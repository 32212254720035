import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactPlayer from 'react-player'
import I18nMessages from '../../components/I18nMessages';
import getMessage from '../../language/getMessage';
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider';
import { InjectedBenHelperProps, withBenHelper } from '../../providers/benHelperProvider';
import { InjectedBenServiceProps, PlansItem, withBenService } from '../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider';
import { formatAmount, getCreditsInfo, getCreditsToQuestions, getPlanName } from '../../lib/utils';
import { AccountType } from '../../providers/providersDefs';
import { GroupPlansTypes } from './PurchasePage';


type PurchasePageProps = InjectedBenHelperProps & InjectedBenAccountProps & RouteComponentProps & InjectedBenServiceProps & InjectedIntlProps & InjectedBenNotificationProps & {
    plans: PlansItem[]
    plansTypes: GroupPlansTypes
    mdValue: string
    mbValue: string
    handleSelectPlan: (licType: number, licYears: number, discount: number) => void
}

const PurchasePageTile: React.FC<PurchasePageProps> = ({
    benHelper,
    benAccount,
    benService,
    benNotification,
    history,
    intl,
    plans,
    plansTypes,
    mdValue,
    mbValue,
    handleSelectPlan
}) => {

    const [isPopupOpen, setIsPopupOpen] = React.useState(false)
    const [selectedPlan, setSelectedPlan] = React.useState<PlansItem | undefined>(undefined)

    React.useEffect(() => {
        if (plans.length > 0) {
            setSelectedPlan( plans[0] )
        }
    }, [plans])

    function getPlanFeaturesDevicesCount(devices: number): string {
        return intl.formatMessage({ id: 'plans-info-page.plan-devices' }, { devices })
    }


    function isOptimal(plan: PlansItem): boolean {
        return ( plansTypes === GroupPlansTypes.PARENTAL_CONTROL && plan.type === AccountType.regular) || 
               ( plan.type >= AccountType.minimal_edu && plan.type <= AccountType.premium_edu )
    }

    function togglePopup(plan?: PlansItem) {
        setIsPopupOpen(!isPopupOpen)
    }



    function renderPlanPopupContent(plan: PlansItem): JSX.Element {

      const commonContent = (
        <>
            <h4>Szczegóły planu kontroli rodzicielskiej</h4>
            <ul className='ml-4'>
                <li>Maksymalna liczba urządzeń: {plan.devices}</li>
                <li>Cena roczna: {formatAmount(plan.price, plan.currency)}</li>
                <li>Cena miesięczna: {formatAmount(plan.monthly, plan.currency)}</li>
            </ul>
        </>
      )
  
      if (plan.type >= AccountType.minimal && plan.type <= AccountType.premium ) { 
          return (
              <>
                <p>
                    Ten plan oferuje funkcje ochrony urządzeń w ramach kontroli rodzicielskiej. Idealny dla użytkowników,
                    którzy potrzebują solidnej ochrony bez dodatkowych funkcji edukacyjnych.
                </p>
                <ul className='ml-4'>
                    <li>Ochrona i filtrowanie </li>
                    <li>Limity czasu dla stron, aplikacji i gier</li>
                    <li>Kontrola Youtube</li>
                    <li>Analiza seriwsów społecznościowych</li>
                    <li>Poddgląd ekranu</li>
                    <li>Blokowanie aplikacji</li>
                </ul>
                {commonContent}
              </>
          )
      } else if (plan.type >= AccountType.minimal_edu && plan.type <= AccountType.premium_edu ) { 
          return (
            <>
                <p>
                    Ten plan łączy w sobie funkcje kontroli rodzicielskiej z narzędziami wspomagającymi naukę.
                    Idealny dla rodziców, którzy potrzebują dla dzieci zarówno ochrony, jak i pomocy w nauce.
                </p>
                <ul className='ml-4'>
                    <li>Wszystkie funkcje planu kontroli rodzicielskiej</li>
                    <li>Narzędzia do rozwiązywania zadań z matematyki, fizyki, chemii, angielskiego, informatyki, pozostałych</li>
                    <li>Dostęp do bazy wiedzy z różnych przedmiotów</li>
                    <li>Personalizowane postępy w nauce</li>
                </ul>
                {commonContent}
                <p>
                    Zobacz jak działa Pomoc w nauce:
                </p>
            
                <ReactPlayer 
                    width="100%"
                    config={{
                    youtube: {
                        playerVars: { showinfo: 1, controls:1, pip:1 }
                    }
                    }}
                    url="https://www.youtube.com/embed/2sjJs9iKzr4?si=G0o_gkiC4rDTAsGg"
                    playing={true} />
            </>
          )
      } else if (plan.type === AccountType.edu) { // Learning Help
          return (
            <>
                <p>
                    Ten plan skupia się wyłącznie na wsparciu edukacyjnym dzieci. Idealny dla osób,
                    które potrzebują zaawansowanych narzędzi do nauki i rozwiązywania problemów. Wystarczy, że wpiszesz treść zadania lub zrobisz zdjęcie a my je rozwiążemy i wytłumaczymy krok po kroku. Natychmiast.
                </p>
                <ul>
                    <li>Zaawansowany system rozwiązywania zadań z różnych przedmiotów</li>
                    <li>Pomoc z matematyki, fizyki, chemii, angielskiego, informatyki, pozostałych</li>
                    <li>Dostęp do bazy wiedzy z różnych przedmiotów</li>
                    <li>Personalizowane postępy w nauce</li>
                </ul>
                <p>
                    Zobacz jak działa Pomoc w nauce:
                </p>
                <ReactPlayer 
                            width="100%"
                            config={{
                            youtube: {
                                playerVars: { showinfo: 1, controls:1, pip:1 }
                            }
                            }}
                            url="https://www.youtube.com/embed/2sjJs9iKzr4?si=G0o_gkiC4rDTAsGg"
                            playing={true} />
              </>
          )
      } else {
        return (
            <>
                <h3>Szczegóły planu</h3>
                {commonContent}
            </>
          )
      }
    }


    function getPlanDescription(plan: PlansItem, line:number)
    {
        if( plan.type >= AccountType.minimal &&  plan.type <= AccountType.premium ) {

            //if( line === 1 ) return "Kontrola rodzicelska"
            if( line === 1 ) return getPlanName(plan.type, intl, 1)
            return " "
        }

        if( plan.type >= AccountType.minimal_edu &&  plan.type <= AccountType.premium_edu ) {
            //if( line === 1 ) return "Kontrola rodzicelska"
            //return "Pomoc w nauce"

            if( line === 1 ) return getPlanName(plan.type % 1000, intl, 1)
            return getPlanName(1000, intl, 1)
        }

        if( plan.type === AccountType.edu ) {
            // if( line === 1 ) return "Pomoc w nauce"
            if( line === 1 ) return getPlanName(plan.type, intl, 1)
            return " "
        }

        return " "
    }


    // Po nowemu, ochrona lub edu lub ochrona+edu
    function renderPlanTile(plan: PlansItem, selectDevicesCnt:boolean, showEduInfo:boolean, showCreditsInfo:boolean): JSX.Element {
        return (
            <Col className="plan-details mb-3" md={mdValue} key={'plan-' + plan.type}>
                <Card  className={isOptimal(plan) ? 'plan optimal-plan' : 'plan'}>
                    <CardBody className={isOptimal(plan) ? 'pt-0 px-0' : 'px-2'}>
                        {isOptimal(plan) && (
                            <Row className="mb-1">
                                <Col xs="12">
                                    <div className="float-right font-weight-bold optimal-plan-label px-3 text-center">
                                        <I18nMessages tagName="span" id="purchase-page.plan.optimal" />
                                    </div>
                                </Col>
                            </Row>
                        )}

                        <CardTitle tag="h2" className="mb-0 pb-0 purchase-tile-title">{getPlanDescription(plan, 1)}</CardTitle>
                        <CardTitle tag="h2" className="mb-0 pb-0 purchase-tile-title">{getPlanDescription(plan, 2)}</CardTitle>

                        <small className="purchase-more-details" onClick={() => togglePopup(plan)} style={{ cursor: 'pointer' }}>szczegóły</small>
                        <div className="old-price" style={{ minHeight: '2.0rem' }}>
                            
                        </div>
                        <div className={isOptimal(plan) ? 'price mx-2' : 'price'}>
                        {plan.discount === 1 && <span className={'old-price'} style={{fontWeight: 'normal'}}>{formatAmount(plan.basePrice, plan.currency)}</span>} {formatAmount(plan.price, plan.currency)}
                            <small className="pl-1">
                                / <I18nMessages tagName="span" id="purchase-page.plan.year" />
                            </small>
                        </div>
                        <div className={isOptimal(plan) ? 'price-monthly py-1 mx-2' : 'price-monthly py-1'}>
                            {formatAmount(plan.monthly, plan.currency)} <I18nMessages id="purchase-page.monthly" />
                        </div>
                        <Row>
                            <Col xs="2"></Col>
                            <Col className="border-top"></Col>
                            <Col xs="2"></Col>
                        </Row>

                        <div className="plan-tile-content max-devices-count text-center mt-1 mb-1">

                            { selectDevicesCnt && (
                                <>
                                    <strong>Maksymalna ilość urządzeń</strong>
                                    <div className="device-container">
                                        {plans.map(p => (
                                            <button
                                                key={p.type}
                                                className={p.type === plan.type ? "device-button device-active" : "device-button"}
                                                onClick={() => { setSelectedPlan(p) }} >
                                                {p.devices}
                                            </button>
                                        ))}
                                    </div>
                                    <I18nMessages tagName="div" id="plans-info-page.plan-devices-types" />
                                </> 
                            )}
                            { showEduInfo && (
                                <> 
                                    Rozwiążemy każde zadanie z dowolnego przedmiotu i wytłumaczymy krok po kroku.
                                    <div className='my-1'/>
                                    Zrób zdjęcie zadania lub wpisz treść. My zajmiemy się resztą. 
                                </> 
                            )}

                            { showCreditsInfo && (
                                <>
                                    <p className='credits-info' style={{marginTop:'5px'}}>{ getCreditsInfo(plan) } *</p>
                                </>
                            )}
                        </div>

                        <Button
                            className="choose-plan-btn-top font-weight-bold"
                            color="primary"
                            onClick={() => { handleSelectPlan(plan.type, 1, plan.discount) }} >
                            <I18nMessages id="purchase-page.select" />
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        );
    }




    // Po staremu, tylko ochrona
    function renderPlanTileLegacy(plan: PlansItem): JSX.Element {
        return (
            <Col className="plan-details mb-3" md={mdValue} key={'plan-' + plan.type}>
                <Card  className={ isOptimal(plan) ? 'plan optimal-plan' : 'plan'}>
                    <CardBody className={plan.type === 200 ? 'pt-0 px-0' : 'px-2'}>
                        { isOptimal(plan) && (
                            <Row className="mb-1">
                                <Col xs="12">
                                    <div className="float-right font-weight-bold optimal-plan-label px-3 text-center">
                                        <I18nMessages tagName="span" id="purchase-page.plan.optimal" />
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {/* <CardTitle tag="h1" className="mb-0 pb-0 purchase-tile-title">{getPlanName(plan.type, intl, 1)}</CardTitle> */}
                        <CardTitle tag="h1" className="mb-0 pb-0 purchase-tile-title">{getPlanDescription(plan, 1)}</CardTitle>
                        
                        <div className="old-price" style={{ minHeight: '2.0rem' }}>
                            {plan.discount === 1 && <span className={'old-price'}>{formatAmount(plan.basePrice, plan.currency)}</span>}
                        </div>
                        <div className={ isOptimal(plan) ? 'price mx-2' : 'price'}>
                            {formatAmount(plan.price, plan.currency)}
                            <small className="pl-1">
                                / <I18nMessages tagName="span" id="purchase-page.plan.year" />
                            </small>
                        </div>
                        <div className={ isOptimal(plan) ? 'price-monthly py-1 mx-2' : 'price-monthly py-1'}>
                            {formatAmount(plan.monthly, plan.currency)} <I18nMessages id="purchase-page.monthly" />
                        </div>
                        <Row>
                            <Col xs="2"></Col>
                            <Col className="border-top"></Col>
                            <Col xs="2"></Col>
                        </Row>

                        <div className="max-devices-count text-center my-3">
                            <strong>{getPlanFeaturesDevicesCount(plan.devices)}</strong>
                            <I18nMessages tagName="div" id="plans-info-page.plan-devices-types" />
                        </div>

                        <Button
                            className="choose-plan-btn-top font-weight-bold"
                            color="primary"
                            onClick={() => { handleSelectPlan(plan.type, 1, plan.discount) }}>
                            <I18nMessages id="purchase-page.select" />
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        );
    }

    function renderPlans() : JSX.Element
    {
        if( selectedPlan === undefined )
            return ( <></> )

        if ( plansTypes === GroupPlansTypes.PARENTAL_CONTROL )
            return renderPlanTileLegacy( selectedPlan )

        let selectDevicesCnt = (selectedPlan.devices > 0)
        let showEduInfo      = (selectedPlan.devices === 0)
        let showCreditsInfo  = (selectedPlan.eduTokens > 0)

        return renderPlanTile(selectedPlan, selectDevicesCnt, showEduInfo, showCreditsInfo)
    }

    return (
        <>
            { renderPlans() }
            
            <Modal isOpen={isPopupOpen} toggle={() => togglePopup()}>
                <ModalHeader  toggle={() => togglePopup()}>
                    <h3>{selectedPlan && getPlanName(selectedPlan.type, intl)}</h3>
                </ModalHeader>
                <ModalBody>
                    {selectedPlan && renderPlanPopupContent(selectedPlan)}
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="primary" onClick={() => { selectedPlan && handleSelectPlan(selectedPlan.type, 1, selectedPlan.discount) }}>
                        Wybierz plan
                    </Button> */}
                    <Button color="light" onClick={() => togglePopup()}>Zamknij</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default withBenHelper(withRouter(withBenNotification(withBenAccount(injectIntl(withBenService(PurchasePageTile))))))